import { closeOS, getFeed, loadOSOpends, openOS } from "../services/api";
import { db } from "../services/db";

export async function loadFeed() {
  const response = await getFeed();
  if (response) {
    await db.os_feed.bulkPut(response);
    return response;
  } else {
    const data = await db.os_feed.toArray();
    return data;
  }
}

export async function osOpen(os) {
  const response = await openOS(os.id);
  if (response) {
    await db.os_feed.delete(os.id);
    await db.os_open.put(os);
    return true;
  }
  return false;
}

export async function loadOsOpened() {
  const response = await loadOSOpends();
  if (response) {
    for (let i = 0; i < response.length; i++) {
      const element = response[i];
      const os = await db.os_open.get(element.id);
      if (os && element.id === os.id) {
      } else {
        await db.os_open.put(element);
      }
    }
  }
  const data = await db.os_open.toArray();
  return data;
}

export async function loadOSClosed() {
  const response = await db.os_closed.toArray();
  return response;
}

export async function osClose(OS) {
  const os = OS;
  os.closed_at = new Date().toJSON();
  await db.os_open.put(os);
}

export async function osSend(OS) {
  const os = OS;
  const formData = new FormData();
  for (let i = 0; i < OS.media.length; i++) {
    const file = OS.media[i].file;
    console.log(OS.media[i].file);
    console.log(`image[${i}]`, file);
    formData.append(`image[${i}]`, file);
  }
  formData.append("closed_at", os.closed_at);
  formData.append("manual", os.manual);
  formData.append("resolution", os.resolution);
  formData.append("materials", JSON.stringify(os.materials));
  console.log("Materias", JSON.stringify(os.materials));
  formData.append("signature", os.signature);
  let response = await closeOS(os.id, formData);
  if (response) {
    await db.os_open.delete(OS.id);
    await db.os_closed.put(os);
    return true;
  }
  return false;
}
