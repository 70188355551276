import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useRecoilState } from "recoil";
import { installPrompt } from "../states";

export default function InstallPopup() {
  const [installState, setInstallState] = useRecoilState(installPrompt);

  function close() {
    setInstallState({
      prompt: false
    })
  }

  function install() {
    if (installState.prompt) {
      installState.event.prompt();

      installState.event.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          console.log("acetiro");
        } else {
          console.log("nao aceitor", choice.outcome);
        }
        setInstallState({
          prompt: false
        })

      });
    }
  }

  return (
    <Dialog
      open={installState.prompt}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Instalar?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Deseja instalar esse aplicativo no seu celular?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Não</Button>
        <Button onClick={install} autoFocus>
          SIM
        </Button>
      </DialogActions>
    </Dialog>
  );
}
