import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "@emotion/styled";

import GalleryView from "../components/GalleryView";
import Signature from "../components/Signature";
import { db } from "../services/db";
import { fileToBlobUrl } from "../utils/Files";
import { useNavigate, useParams } from "react-router-dom";
import { osClose } from "../controllers/OSController";
import SignatureCanvas from "../components/SignatureCanvas";
import { useRecoilState } from "recoil";
import { loadingState, snackbar } from "../states";
import AlertDialog from "../components/AlertDialog";

const Text = styled(TextField)`
  width: 100%;
  margin-top: 10px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0;
`;

export default function OS() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [, setSnack] = useRecoilState(snackbar);
  const [openModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [, setIsLoadingModal] = useRecoilState(loadingState);
  const [showSignature, setShowSignature] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [currentAmount, setAmount] = useState("");
  const [currentName, setName] = useState("");
  const [os, setOS] = useState({
    resolution: "",
    signature: null,
    manual: "",
    materials: [],
    media: [],
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function addMaterial() {
    const materials = os.materials ? os.materials : [];
    materials.push({ amount: currentAmount, name: currentName });
    const newOS = { ...os, materials: materials };
    setOS(newOS);
    saveOS(newOS);
    setAmount("");
    setName("");
    setOpen(false);
  }

  function removeMaterial(idx) {
    const newItems = [...os.materials];
    newItems.splice(idx, 1);
    const newOS = { ...os, materials: newItems };
    setOS(newOS);
    saveOS(newOS);
  }

  useEffect(() => {
    async function load() {
      const os = await db.os_open.get(parseInt(id));
      if (os.closed_at) {
        navigate(`/os/${os.id}/send`);
      }
      let len = os.media ? os.media.length : 0;
      let media = [];
      for (let i = 0; i < len; i++) {
        const file = os.media[i].file;
        const blobUrl = await fileToBlobUrl(file);
        media.push({ name: os.media[i].name, file, url: blobUrl });
      }
      setMediaData(media);
      setOS((prev) => ({ ...prev, ...os }));
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveOS(newOS) {
    console.log(newOS ?? os);
    await db.os_open.put(newOS ?? os);
  }

  async function addMedia(newMedias) {
    const mediaOS = mediaData ?? [];
    const newOS = { ...os, media: [...(os.media ?? []), ...newMedias] };

    for (let i = 0; i < newMedias.length; i++) {
      let url = await fileToBlobUrl(newMedias[i].file);
      mediaOS.push({ name: newMedias[i].name, file: newMedias[i].file, url });
    }
    setMediaData(mediaOS);
    setOS(newOS);
    saveOS(newOS);
  }

  async function removeMedia(idx) {
    let newItems = [...mediaData];
    newItems = newItems.filter((x) => x.name !== idx);
    const newOS = { ...os, media: newItems };
    setOS(newOS);
    saveOS(newOS);
    setMediaData(newItems);
  }

  async function finish() {
    if (!os.resolution) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Serviço realizado não preenchido",
        severity: "error",
      }));
      return;
    }
    if (!os.signature) {
      setSnack((prev) => ({
        ...prev,
        show: true,
        message: "Assinatura não preenchida",
        severity: "error",
      }));
      return;
    }
    setShowModal(true);
  }

  async function confirmFinish() {
    setShowModal(false);
    setIsLoadingModal({
      show: true,
      message: "Finalizando OS. Aguarde!",
    });
    await osClose(os);
    setIsLoadingModal((prev) => ({ ...prev, show: false }));
    navigate(`/os/${id}/send`);
  }

  function saveSignature(data) {
    const newOS = { ...os, signature: data };
    console.log(data);
    setOS(newOS);
    saveOS(newOS);
    setShowSignature(false);
  }

  if (showSignature)
    return (
      <SignatureCanvas
        onSave={saveSignature}
        onCancel={() => setShowSignature(false)}
      />
    );

  return (
    <div>
      <Typography>OS Nº: {os?.id}</Typography>
      <Typography>CLIENTE: {os?.posto?.nome_fantasia}</Typography>
      <Typography>DESCRIÇÃO: {os?.description}</Typography>
      <Divider variant="fullWidth" />
      <Typography mt={2}>Descreva o serviço efetuado</Typography>
      <Divider variant="fullWidth" />
      <Text
        id="outlined-basic"
        label="Serviço Realizado"
        variant="outlined"
        multiline
        value={os?.resolution}
        onChange={(e) =>
          setOS((prev) => ({ ...prev, resolution: e.target.value }))
        }
        onBlur={() => saveOS(os)}
        rows={2}
      />
      <Typography marginTop={2}>
        Senha de desbloqueio: (caso alterada)
      </Typography>
      <Divider variant="fullWidth" />
      <Text
        id="outlined-basic"
        label="Senha de desbloqueio"
        variant="outlined"
        value={os?.manual}
        inputProps={{ maxLength: 4 }}
        onChange={(e) => setOS((prev) => ({ ...prev, manual: e.target.value }))}
        onBlur={() => saveOS(os)}
      />

      <Typography marginTop={2}>Fotos/Videos:</Typography>
      <Divider variant="fullWidth" />
      <GalleryView data={mediaData} setData={addMedia} onRemove={removeMedia} />
      <Typography marginTop={2}>Materiais:</Typography>
      <Divider variant="fullWidth" />
      {os.materials?.length > 0 && (
        <Paper>
          <List>
            {os.materials?.map((map, idx) => (
              <ListItem
                disablePadding
                key={idx}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeMaterial(idx)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemButton>
                  <ListItemText primary={`${map.amount}x ${map.name}`} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      <Button onClick={handleOpen}>Adicionar Material</Button>
      <Typography marginTop={2}>Assinatura do cliente:</Typography>
      <Signature onClick={() => setShowSignature(true)} data={os.signature} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Material Utilizado</DialogTitle>
        <DialogContent>
          <Text
            id="outlined-basic"
            label="Quantidade"
            variant="outlined"
            type="number"
            value={currentAmount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Text
            id="outlined-basic"
            label="Nome do material"
            variant="outlined"
            value={currentName}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={addMaterial} autoFocus>
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
      <Divider variant="fullWidth" />
      <Action>
        <Button variant="contained" onClick={finish}>
          Finalizar
        </Button>
      </Action>
      <AlertDialog
        open={openModal}
        title="Deseja finalizar?"
        description="Ao finalizar não será possível mais editar, continuar?"
        onConfirm={confirmFinish}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
}
