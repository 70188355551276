import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import OSCard from "../components/OSCard";
import { loadOsOpened } from "../controllers/OSController";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState } from "../states";
import { Typography } from "@mui/material";

function OpenedOS() {
  const [loadingModal, setIsLoadingModal] = useRecoilState(loadingState);
  const [osOpens, setOsOpens] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function load() {
      setIsLoadingModal({
        show: true,
        message: "Carregando",
      });
      let data = await loadOsOpened();
      setOsOpens(data);
      setIsLoadingModal((prev) => ({ ...prev, show: false }));
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Container = styled.div((props) => ({
    height: "auto",
  }));

  async function open(os) {
    navigate("/os/" + os.id);
  }

  return (
    <Container>
      {osOpens.map((os) => (
        <OSCard
          onOpen={() => open(os)}
          key={os.id}
          os={{
            id: os.id,
            opened_at: `Aberto: ${new Date(os.opened_at).toLocaleString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}`,
            description: os.description,
            distance: `${os.distance / 1000} km`,
            customer: {
              lat: os.posto.latitude,
              lng: os.posto.longitude,
              nome_fantasia: os.posto.nome_fantasia,
            },
          }}
        />
      ))}
      {!loadingModal.show && osOpens.length === 0 && (
        <Typography>Nenhuma OS aqui!</Typography>
      )}
    </Container>
  );
}

export default OpenedOS;
