import {
  ListItemText,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import React from "react";
import styled from "@emotion/styled";
import { logout } from "../controllers/AuthController";
import { useRecoilValue } from "recoil";
import { userState } from "../states";

const Container = styled.div`
  padding: 10px 0;
  text-align: center;
`;
export default function Profile() {
  const user = useRecoilValue(userState)
  async function doLogout() {
    await logout();
    window.location = "/";
  }
  return (
    <Paper>
      <Container>
        <span>Olá, {user.user.name}!</span>
      </Container>
      <MenuList>
        <MenuItem onClick={doLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
