import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import styled from "@emotion/styled";
import logo from "../assets/logo192.png";

const ToolBarHeader = styled(Toolbar)`
  justify-content: space-between;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = () => {
  return (
    <AppBar position="fixed">
      <ToolBarHeader>
        <ImgContainer>
          <img src={logo} width={25} height={25} style={{ marginRight: 10 }} alt="EVO LOGO" />
          <Typography variant="h6">EVO OS</Typography>
        </ImgContainer>
        <small>VERSÃO: {process.env.REACT_APP_VERSION}</small>
      </ToolBarHeader>
    </AppBar>
  );
};

export default Header;
