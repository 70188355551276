import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Home from "./pages/Home";
import InstallPage from "./pages/InstallPage";
import Login from "./pages/Login";
import OS from "./pages/OS";
import OSToSend from "./pages/OSToSend";
import Profile from "./pages/Profile";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Home />
        <ScrollRestoration />
      </>
    ),
  },
  {
    path: "/os/:id",
    element: (
      <>
        <ScrollRestoration />
        <OS />
      </>
    ),
  },
  {
    path: "/os/:id/send",
    element: (
      <>
        <ScrollRestoration />
        <OSToSend />
      </>
    ),
  },
  {
    path: "/install",
    element: <InstallPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
]);

export const unauthRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollRestoration />
        <Login />
      </>
    ),
  },
  {
    path: "/install",
    element: <InstallPage />,
  },
]);
export function Routes() {
  return <RouterProvider router={router} />;
}

export function UnauthRoutes() {
  return <RouterProvider router={unauthRouter} />;
}
