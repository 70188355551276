import Dexie from "dexie";

export const db = new Dexie("appOSEVO");

db.version(6).stores({
  images: "name, file",
  user: "id,token, name",
  os_feed:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at",
  os_open:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at, opened_at, resolution, media, manual, assinatura",
  os_closed:
    "id, description, distance, distance_back, posto.id, posto.CNPJ, posto.nome_fantasia, posto.latitude, posto.longitude, posto.name, created_at, opened_at, resolution, media, manual, assinatura, closed_at",
});
