import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Avatar,
  CircularProgress,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import InputMask from 'react-input-mask';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { snackbar, userState } from "../states";
import { login } from "../controllers/AuthController";

const PaperStyled = styled(Paper)`
  padding: 20px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  width:500px;
`;

export default function Login() {
  const [, setSnack] = useRecoilState(snackbar);
  const [, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0", height: 50 };

  async function doLogin() {
    setIsLoading(true);

    login(
      { phone, password },
      (data) => {
        console.log("set date", data);
        const { user: userData, ...token } = data;
        setUser({ user: userData, token });
      },
      () => {
        setSnack((prev) => ({
          ...prev,
          show: true,
          message: "Usuário/Senha incorretos",
          severity: "error",
        }));
        setIsLoading(false);
      }
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <PaperStyled elevation={10}>
        <Grid align="center">
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>Entrar</h2>
        </Grid>
        <InputMask mask="(99) 9 9999-9999"
          maskChar=" "
          value={phone}
          onChange={(e) => setPhone(e.target.value)}>
          {() => <TextField
            label="Telefone"
            placeholder="Telefone"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            fullWidth
            required
          />
          }
        </InputMask>
        <TextField
          style={{ marginTop: 15 }}
          label="Senha"
          placeholder="Senha"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          disabled={isLoading}
          onClick={doLogin}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (
            <span>Entrar</span>
          )}
        </Button>
        <Typography>
          {/* <Link href="#">Esqueceu a senha?</Link> */}
        </Typography>
      </PaperStyled>
    </Box>
  );
}
