import { login as loginAPI } from "../services/api";
import { db } from "../services/db";

export async function login({ phone, password }, onSuccess, onError) {
  const response = await loginAPI({ phone, password });
  if (response) {
    if (onSuccess)
      onSuccess(response);
    const { user, ...token } = response;
    db.user.put({
      id: 1,
      token: token,
      user: user,
    });
    return true;
  }
  if (onError) onError(response);
}

export async function logout() {
  return await db.user.delete(1);
}
