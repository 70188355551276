import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/AddAPhoto";
import AlertDialog from "./AlertDialog";

import LoadingProgressiveDialog from "./LoadingProgressiveDialog";

const SquareButton = styled.div`
  min-width: 120px;
  height: 120px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: #3f51b5;
  color: white;
`;

const Label = styled.label`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-width: 100vw;
  padding-bottom: 10px;
  margin-top: 5px;
`;

const Img = styled.img`
  min-width: 120px;
  max-width: 120px;
  max-height: 120px;
  min-height: 120px;
  margin-right: 10px;
  object-fit: cover;
`;

export default function GalleryView({ data, setData, onRemove }) {
  const [openModal, setOpenModal] = useState(false);
  const [modalItemId, setModalItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);

  function confirmRemove(idx) {
    setModalItemId(idx);
    setOpenModal(true);
  }

  function cancelRemoveModal() {
    setOpenModal(false);
    setModalItemId(false);
  }

  async function removeMedia() {
    onRemove(modalItemId);
    setOpenModal(false);
  }

  async function selectFiles(event) {
    setIsLoading(true);
    const files = event.target.files;
    let toAdd = [];
    for (let i = 0; i < files.length; i++) {
      toAdd.push({
        name: files[i].name,
        file: files[i],
      });
    }
    setData(toAdd);
    setIsLoading(false);
  }

  return (
    <Container className="scroll">
      <SquareButton>
        <Label htmlFor="upload">
          <AddIcon />
          <input
            ref={inputRef}
            type={"file"}
            id="upload"
            style={{ display: "none" }}
            onChange={selectFiles}
            accept="image/*"
            multiple
          />
        </Label>
      </SquareButton>
      {data.map((img, idx) => (
        <Img
          key={idx}
          src={img.url}
          alt={img.name}
          onClick={() => confirmRemove(img.name)}
        />
      ))}
      <AlertDialog
        open={openModal}
        title="Deseja remover?"
        description="Deseja remover o arquivo da lista de envio?"
        onConfirm={removeMedia}
        onCancel={cancelRemoveModal}
      />
      <LoadingProgressiveDialog open={isLoading} title={"Carregando imagens"} />
    </Container>
  );
}
