import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import OSCard from "../components/OSCard";
import { loadFeed, osOpen } from "../controllers/OSController";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadingState, snackbar } from "../states";
import { Typography } from "@mui/material";

function Feed() {
  const [, setSnack] = useRecoilState(snackbar);
  const [loadingModal, setIsLoadingModal] = useRecoilState(loadingState);
  const [osFeed, setOsFeed] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoadingModal({
      show: true,
      message: "Carregando",
    });
    async function load() {
      let data = await loadFeed();
      setOsFeed(data);
      setIsLoadingModal((prev) => ({ ...prev, show: false }));
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Container = styled.div((props) => ({
    height: "auto",
  }));

  async function open(os) {
    setIsLoadingModal({
      show: true,
      message: "Abrindo OS",
    });
    let result = await osOpen(os);
    setIsLoadingModal((prev) => ({ ...prev, show: false }));
    if (result) navigate("/os/" + os.id);
    else
      setSnack({
        show: true,
        severity: "error",
        message: "Não foi possível abrir. Verifique conexão com a internet.",
      });
  }

  return (
    <Container>
      {osFeed.map((os) => (
        <OSCard
          onOpen={() => open(os)}
          key={os.id}
          os={{
            id: os.id,

            description: os.description,
            distance: `${os.distance / 1000} km`,
            customer: {
              lat: os.posto.latitude,
              lng: os.posto.longitude,
              nome_fantasia: os.posto.nome_fantasia,
            },
          }}
        />
      ))}
      {!loadingModal.show && osFeed.length === 0 && (
        <Typography>Nenhuma OS aqui!</Typography>
      )}
    </Container>
  );
}

export default Feed;
