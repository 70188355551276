import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import OSCard from "../components/OSCard";
import { loadOSClosed } from "../controllers/OSController";
import { useRecoilState } from "recoil";
import { loadingState } from "../states";
import { Typography } from "@mui/material";

function FinishedOS() {
  const [loadingModal, setIsLoadingModal] = useRecoilState(loadingState);
  const [closedOS, setClosedOS] = useState([]);

  useEffect(() => {
    setIsLoadingModal({
      show: true,
      message: "Carregando",
    });
    async function load() {
      let data = await loadOSClosed();
      setClosedOS(data);
      setIsLoadingModal((prev) => ({ ...prev, show: false }));
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Container = styled.div((props) => ({
    height: "auto",
  }));

  return (
    <Container>
      {closedOS.map((os) => (
        <OSCard
          key={os.id}
          os={{
            id: os.id,
            opened_at: `Fechado: ${new Date(os.closed_at).toLocaleString([], {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}`,
            description: os.description,
            distance: `${os.distance / 1000} km`,
            customer: {
              lat: os.posto.latitude,
              lng: os.posto.longitude,
              nome_fantasia: os.posto.nome_fantasia,
            },
          }}
        />
      ))}
      {!loadingModal.show && closedOS.length === 0 && (
        <Typography>Nenhuma OS aqui!</Typography>
      )}
    </Container>
  );
}

export default FinishedOS;
