import { atom } from "recoil";

export const loadingState = atom({
  key: "loadingState",
  default: {
    show: false,
    message: "",
  },
});

export const snackbar = atom({
  key: "snackbar",
  default: { show: false, message: "ola", severity: "success" },
});

export const auth = atom({
  key: "auth",
  default: {
    isLogged: false,
    token: null,
    name: null,
  },
});

export const userState = atom({
  key: "user",
  default: {
    token: null,
    user: null,
  },
});

export const installPrompt = atom({
  key: 'install',
  default: {
    prompt: false,
    event: null,
  }
});
