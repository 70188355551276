import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Send } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useRecoilState } from "recoil";
import { loadingState } from "../states";
import { db } from "../services/db";
import { osSend } from "../controllers/OSController";
import AlertDialog from "../components/AlertDialog";
const Container = styled.div`
  position: absolute;
  top: 90px;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

const IconContainer = styled.div`
  width: 100px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  font-size: 50px;
  justify-content: center;
  align-items: center;
  color: green;
`;

const ActionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export default function OSToSend() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setIsLoadingModal] = useRecoilState(loadingState);

  const [responseData, setResponseData] = useState({
    success: false,
    show: false,
    title: "",
    text: "",
  });

  useEffect(() => {
    async function load() {
      const os = await db.os_open.get(parseInt(id));
      if (!os) {
        navigate(`/`);
      }
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function sendOS() {
    const os = await db.os_open.get(parseInt(id));
    setIsLoadingModal({
      show: true,
      message: "Enviando OS. Aguarde!",
    });
    const response = await osSend(os);
    setIsLoadingModal((prev) => ({ ...prev, show: false }));
    if (response) {
      setResponseData({
        success: true,
        show: true,
        title: "Sucesso",
        text: "OS Enviada com sucesso! Obrigado!",
      });
    } else {
      setResponseData({
        success: false,
        show: true,
        title: "Falha",
        text: "Falha ao enviar OS " + os.id + " Tente novamente mais tarde",
      });
    }
  }

  function continueOS() {
    navigate("/");
  }
  return (
    <Container>
      <IconContainer>
        <Send fontSize="50px" />
      </IconContainer>
      <span style={{ fontWeight: "bold" }}>ENVIAR</span>
      <ActionContainer>
        <span>OS finalizada, você deve envia-lá</span>
        <Button variant="contained" onClick={sendOS}>
          ENVIAR
        </Button>
      </ActionContainer>
      <AlertDialog
        open={responseData.show}
        title={responseData.title}
        description={responseData.text}
        onConfirm={continueOS}
        ok={"OK"}
      />
    </Container>
  );
}
