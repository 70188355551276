import axios from "axios";
import { db } from "./db";

export const baseURL = 'https://dashboard.evoautomacoes.com.br/mso'

const api = axios.create({
  baseURL
});
api.interceptors.request.use(
  async (config) => {
    const user = (await db.user.toArray())[0];
    if (user)
      config.headers.Authorization = `Bearer ${user.token.access_token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// const uploadMultipleBlobImages = async (blobUrls) => {
//   try {
//     const formData = new FormData();
//     for (let i = 0; i < blobUrls.length; i++) {
//       const file = await fetch(blobUrls[i]).then((r) => r.blob());
//       formData.append(`image[${i}]`, file);
//     }
//     const result = await api.post("/image", formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       onUploadProgress: (progressEvent) => {
//         const { loaded, total } = progressEvent;
//         let percent = Math.floor((loaded * 100) / total);
//         console.log(`${loaded}kb of ${total}kb | ${percent}%`);
//       },
//     });
//     console.log(result);
//   } catch (error) {
//     console.error(error);
//   }
// };

export async function login({ phone, password }) {
  try {
    const response = await api.post("/auth/login", { phone, password });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  } catch (err) {
    return false;
  }
}

export async function getFeed() {
  try {
    const response = await api.get("/os/feed");
    return response.data;
  } catch (err) {
    return false;
  }
}
export async function loadOSOpends() {
  try {
    const response = await api.get("/os/opens");
    return response.data;
  } catch (err) {
    return false;
  }
}

export async function openOS(id) {
  try {
    const response = await api.post(`/os/${id}/open`);
    return response.data;
  } catch (err) {
    return false;
  }
}
export async function closeOS(id, data) {
  try {
    const response = await api.post(`/os/${id}/close`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    return false;
  }
}
