import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import AddIcon from "@mui/icons-material/Add";

const Container = styled.div`
  width: 100%;
`;

const FullButton = styled(Button)`
  min-width: 100%;
  height: 120px;
  margin-right: 10px;
  padding: 8px;
  border-radius: 4px;
  background-color: #3f51b5;
  color: white;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
`;

export default function Signature({ onClick, data }) {
  return (
    <Container>
      <FullButton onClick={onClick}>
        {data ? <Img src={data} /> : <AddIcon />}
      </FullButton>
    </Container>
  );
}
