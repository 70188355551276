import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: absolute;
  top: 90px;
  bottom: 10px;
  left: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function InstallPage() {
  const navigate = useNavigate();
  function install() {
    window.defferedPrompt.prompt();

    window.defferedPrompt.userChoice.then((choice) => {
      if (choice.outcome === "accepted") {
        console.log("acetiro");
        navigate("/");
      } else {
        console.log("nao aceitor", choice.outcome);
      }
      window.defferedPrompt = null;
    });
  }
  return (
    <Container>
      Instale o aplicativo para continuar
      <Button variant="contained" onClick={install}>
        Instalar
      </Button>
    </Container>
  );
}
