import React, { useState } from "react";
import BottomNav from "../components/BottomNav";
import Feed from "./Feed";
import FinishedOS from "./FinishOS";
import OpenedOS from "./OpenedOS";
import styled from "@emotion/styled";
import Profile from "./Profile";

const Container = styled.div`
  margin-bottom: 90px;
`;

export default function Login() {
  const [value, setValue] = useState(0);

  return (
    <>
      <Container>
        {value === 0 && <Feed />}
        {value === 1 && <OpenedOS />}
        {value === 2 && <FinishedOS />}
        {value === 3 && <Profile />}
      </Container>
      <BottomNav value={value} setValue={setValue} />
    </>
  );
}
